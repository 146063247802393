'use client';

import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import IconButton from 'orbit/src/components/Buttons/IconButton';
import Checkbox from 'orbit/src/components/Checkbox/Checkbox';
import {
  Email, Padlock, Eye, ShutEye, Account, Telephone,
} from 'orbit/src/components/Icons';
import Input from 'orbit/src/components/Inputs/Input';
import StepChecker from 'orbit/src/components/StepChecker/StepChecker';
import { CustomError } from 'common/lib/errors';
import Button from '@/src/components/Button/Button';
import GroundsureIoLogoFull from '@/src/components/Logos/GroundsureIoLogo/GroundsureIoLogoFull';
import UnderlinedLink from '@/src/components/Typography/UnderlinedLink';
import useRegisterForm from '@/src/hooks/useRegisterForm';
import { post } from '@/src/services/xhr';

export default function SignUp() {
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serverForbiddenError, setServerForbiddenError] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleChange, errors, canSubmit, values, passwordChecklist,
  } = useRegisterForm();

  const onSignUp = async () => {
    setIsLoading(true);
    setServerForbiddenError(null);
    setIsError(false);

    const {
      name, email, password, phone, marketing_opt_out,
    } = values;

    try {
      await post('/api/auth/new-user', {
        name: name.trim(), email, password, phone, marketing_consent: !marketing_opt_out,
      }, { cache: 'no-cache' });

      const auth = await signIn('credentials', {
        email,
        password,
        redirect: false,
        callbackUrl: '/',
      });

      if (!auth?.ok || auth?.error) {
        router.push('/login');
      }

      if (!auth?.error) {
        console.log('Auth success');
        router.push('/');
      }
    } catch (err) {
      const customError = err as CustomError;
      if (customError.httpCode === 403) {
        setServerForbiddenError(customError.message);
      } else {
        setIsError(true);
      }
      console.error('sign up error', err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="flex flex-col gap-4 px-10 py-6 w-full sm:max-w-[auto] sm:flex-row-reverse items-center">
        <Link className="w-fit h-fit ml-auto" href="/login">
          <Button
            className="w-fit h-fit ml-auto whitespace-nowrap"
            size="sm"
            type="button"
            styleType="outline"
            text="login"
          />
        </Link>
        <GroundsureIoLogoFull />
      </div>

      {isError && (
      <div className="bg-rose-100 py-3 w-full lg:pl-6">
        <span className="text-white text-xs sm:text-sm block text-center lg:text-left">Something went wrong try again.</span>
      </div>
      )}

      {serverForbiddenError && (
      <div className="bg-amber-60 py-3 w-full lg:pl-6">
        <span className="text-blue-100 text-xs sm:text-sm block text-center lg:text-left">{serverForbiddenError}</span>
      </div>
      )}

      <div className="mt-10 pb-10 flex flex-col w-full max-w-[482px]">

        <h1 className="font-museo-700 text-3xl text-blue-100 mb-4 text-center">
          INTELLIGENCE
          {' '}
          <br />
          OPTIMISED
        </h1>
        <h2 className="text-xl text-blue-100 text-center mb-10">
          Sign up to start investigating for
          <br />
          free today
        </h2>

        <form
          className="w-full px-10 flex flex-col justify-center gap-6"
        >
          <Input
            type="name"
            name="name"
            id="name"
            placeholder="Full name"
            required
            useErrorLabel
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            startIcon={(
              <Account className="ml-4 fill-grey-500" />
            )}
          />

          <Input
            type="phone"
            name="phone"
            id="phone"
            placeholder="Phone number"
            required
            useErrorLabel
            error={errors.phone}
            value={values.phone}
            onChange={handleChange}
            startIcon={(
              <Telephone className="ml-4 fill-grey-500" />
            )}
          />

          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            required
            useErrorLabel
            error={errors.email}
            value={values.email}
            onChange={handleChange}
            startIcon={(
              <Email className="ml-4 fill-grey-500" />
            )}
          />

          <div>
            <Input
              className="mb-1 w-full"
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              required
              value={values.password}
              onChange={handleChange}
              placeholder="Password"
              onFocus={() => setPasswordFocused(true)}
              startIcon={<Padlock className="ml-4 fill-grey-500" />}
              endIcons={[
                <IconButton
                  type="button"
                  variant="embedded"
                  icon={showPassword ? <Eye /> : <ShutEye />}
                  onClick={() => (setShowPassword((prevState) => !prevState))}
                />]}
            />

            <StepChecker
              list={passwordChecklist}
              isVisible={passwordFocused && passwordChecklist.length !== 0}
            />
          </div>

          <Checkbox
            className="mr-auto mb-4"
            id="terms"
            name="terms"
            onChange={handleChange}
            checked={values.terms}
            error={errors.terms}
          >
            <span className="text-sm text-blue-100 leading-5">
              Please read and agree to our
              {' '}
              <UnderlinedLink
                external
                href="https://www.groundsure.com/terms-and-conditions"
              >
                Terms and Conditions

              </UnderlinedLink>
              {' '}
              and
              {' '}
              <UnderlinedLink
                external
                href="https://www.groundsure.com/groundsure-privacy-policy"
              >
                Privacy Policy

              </UnderlinedLink>
            </span>
          </Checkbox>

          <Checkbox
            className="mr-auto mb-6"
            id="usage"
            name="usage"
            onChange={handleChange}
            checked={values.usage}
            error={errors.usage}
          >
            <span className="text-sm text-blue-100 leading-5">
              Please read and agree to our
              {' '}
              <UnderlinedLink
                external
                href="https://www.groundsure.com/groundsure-io-terms-of-use"
              >
                Fair Usage Policy
              </UnderlinedLink>
            </span>
          </Checkbox>

          <Button
            type="button"
            disabled={!canSubmit || isLoading}
            text={isLoading ? 'Creating account...' : 'Create account'}
            className="w-full mb-6"
            onClick={onSignUp}
          />

          <span className="font-museo-300 text-sm text-blue-100 leading-5 mb-6">
            We&apos;ll keep you updated on product news and special offers.
            Using the contact details you provided, we&apos;ll send you occasional emails
            related to our products and services.
            <br />
            You can opt out of these communications at any time:
          </span>

          <Checkbox
            className="mr-auto mb-6"
            id="marketing_opt_out"
            name="marketing_opt_out"
            onChange={handleChange}
            checked={!!values.marketing_opt_out}
          >

            <span className="font-museo-300 text-sm text-blue-100 leading-5">
              Opt out of product updates and offers
            </span>
          </Checkbox>
        </form>
      </div>
    </>
  );
}
