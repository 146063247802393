import { useEffect, useState } from 'react';
import useForm from 'orbit/src/hooks/useForm';
import { newUserValidator } from '../utils/validator';

const initialValues = {
  name: '',
  email: '',
  password: '',
  phone: '',
  terms: false,
  usage: false,
  marketing_opt_out: false,
};

const useResetPasswordForm = () => {
  const [
    passwordChecklist,
    setPasswordChecklist,
  ] = useState<{ text: string, pass: boolean }[]>([]);
  const {
    handleChange, errors, isValid, values, validateForm, setValue,
  } = useForm(
    initialValues,
    newUserValidator(setPasswordChecklist),
  );

  useEffect(() => {
    if (initialValues) {
      validateForm(initialValues, []);
    }
  }, []);

  return {
    passwordChecklist,
    canSubmit: isValid,
    updateEmail: (email: string) => setValue('email', email),
    handleChange,
    errors,
    values,
  };
};

export default useResetPasswordForm;
