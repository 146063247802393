import { twMerge } from 'tailwind-merge';
import Tick from '../Icons/Tick';

export type CheckboxProps = {
  children?: React.ReactNode,
  name: string,
  error?: string,
  variant?: 'sm' | 'md',
  checked: boolean,
} & React.ComponentPropsWithoutRef<'input'>;

const Checkbox = ({
  id, name, className, children, checked = false, variant = 'md', ...rest
}: CheckboxProps) => {
  const boxSize = variant === 'md' ? 'h-[24px] min-w-[24px]' : 'h-[16px] min-w-[16px]';

  return (
    <label htmlFor={id} className={twMerge('flex items-center w-fit', className)}>
      <input
        {...rest}
        type="checkbox"
        id={id}
        name={name}
        className="hidden"
      />
      <div
        tabIndex={0}
        role="checkbox"
        aria-checked
        className={twMerge('tabindex=\'0\' flex items-center justify-center rounded mr-2 cursor-pointer bg-mauve-20 hover:opacity-60', boxSize)}
      >

        {checked && <Tick size={variant === 'md' ? 'md' : 'sm'} />}
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
